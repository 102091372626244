<template>
  <transition name="fade">
    <form @keydown="form.onKeydown($event)" @submit.prevent="setDayOff">

      <div class="row" v-if="!is_day_off && !day_off_responsible_id">
        <div class="col-12" v-bind:class="{ 'has-error': form.errors.has('day_off_responsible_id') }">
          <div class="form-group">

            <label for="responsible_id" class="font-medium font-small">
              Временный ответственный
            </label>
            <multiselect :allow-empty="false"
                         :options="employees"
                         :searchable="false"
                         id="responsible_id"
                         @input="() => {
                               form.responsible_id = responsible ? responsible.id : null;
                             }"
                         deselect-label="Убрать"
                         label="title"
                         placeholder="Временный ответственный"
                         required
                         select-label="Выбрать"
                         selected-label="Выбрано"
                         title="Временный ответственный"
                         track-by="id"
                         v-model="responsible">
              <template slot="noResult">Не найдено</template>
              <template slot="noOptions">Данные отсутствуют</template>
            </multiselect>
            <HasError :form="form" field="responsible_id" />
          </div>

          <div class="form-group">
            <input class="pointer"
                   id="is_vacation"
                   style="transform: scale(1.1);"
                   type="checkbox"
                   v-model="form.is_vacation">
            <label for="is_vacation" class="margin-l-5 padding-l-10">
              Отпуск
            </label>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <h5 v-if="responsible">
          Временный исполнитель: <strong>{{ responsible.name }}</strong>
        </h5>
      </div>

      <br />

      <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные." />

      <Button :disabled="form.busy"
              :form="form"
              :loading="form.busy"
              class="btn btn-lg btn-success pull-right"
              style="min-width: 100px;">
        <i class="fa fa-save"></i>
      </Button>
    </form>
  </transition>
</template>

<script>
import Form from "vform";
import Multiselect from "vue-multiselect";

Form.axios = API.apiClient;

export default {
  name: "set-day-off-form",

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  props: {
    employee_id: {
      type: Number,
      required: true,
    },
    is_day_off: {
      type: Number,
      required: true,
    },
    day_off_responsible_id: {
      type: Number,
      required: false,
    },
  },

  data: () => ({
    employees: [],
    responsible: null,

    form: new Form({
      is_day_off: false,
      is_vacation: false,
      responsible_id: null,
    }),
  }),

  async created() {
    try {
      await this.getEmployees();

      this.form.responsible_id = this.day_off_responsible_id;

      this.responsible = this.employees.find((employee) => {
        return employee.id === this.form.responsible_id;
      });

      if (!this.is_day_off && !this.day_off_responsible_id) {
        this.form.is_day_off = true;
      } else {
        this.form.is_day_off = false;
        this.form.responsible_id = null;
      }
    } catch (error) {
      errorHandler(error);
    }
  },

  computed: {

  },

  methods: {
    async getEmployees() {
      try {
        const clients_response = await API.apiClient.get("/employees", {
          params: {
            is_paginated: 0,
            filters_query: {
              role_ids: [1, 2, 3, 4, 6],
              from_same_city: true,
              without_day_off: true,
            },
          }
        });
        this.employees = clients_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async setDayOff() {
      await this.form.put("/employees/set_day_off/" + this.employee_id).then(() => {
        showSuccess();
        this.EventBus.$emit("hide-quick-create-client-modal");
        window.location.reload();
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
};
</script>

<style>

</style>
